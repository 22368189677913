import { graphql } from '../../__graphqlTypes';

export const UPDATE_LAV_OPEN_PREFERENCE = graphql(`
    mutation UpdateLavOpenPreference($input: UpdateLavPreferenceInput!) {
        updateLavPreference(input: $input) {
            ... on UpdateLavPreferenceSuccess {
                user {
                    id
                    lavPreferenceOpen
                }
            }
            ... on UpdateLavPreferenceError {
                code
                message
            }
        }
    }
`);
