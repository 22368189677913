import { useMutation } from '@apollo/client';
import {
    UPDATE_LAV_OPEN_PREFERENCE,
    UpdateLavOpenPreferenceMutation,
    UpdateLavOpenPreferenceMutationVariables,
} from '@deltasierra/frontend/graphql';
import { useDebounceFn } from '@deltasierra/react/hooks/common';
import { useCallback, useState } from 'react';
import { LeftNavigationProps } from '../LeftNavigation';

export function useLeftNavigationDrawer(
    isOpenDefault: boolean,
): Pick<LeftNavigationProps, 'isOpenDrawer' | 'onClickHamburger'> {
    const [isOpenDrawer, setIsOpenDrawer] = useState(isOpenDefault);

    const onClickHamburger = useCallback(() => setIsOpenDrawer(prev => !prev), []);

    const [onUpdateOpen] = useMutation<UpdateLavOpenPreferenceMutation, UpdateLavOpenPreferenceMutationVariables>(
        UPDATE_LAV_OPEN_PREFERENCE,
    );
    const onTriggerUpdatePreference = useCallback(() => {
        void onUpdateOpen({
            variables: { input: { newLavPreference: isOpenDrawer } },
        });
    }, [isOpenDrawer, onUpdateOpen]);
    useDebounceFn(onTriggerUpdatePreference, 4000);

    return {
        isOpenDrawer,
        onClickHamburger,
    };
}
