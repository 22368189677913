import { graphql } from '../../__graphqlTypes';

export const USER_FEATURES_FRAGMENT = graphql(`
    fragment UserFeaturesFragment on UserFeatures {
        enableCropResizeAssetLibraryV2
        enableEmailTemplateDivider
        enableEmailTemplatePlaceholder
        enableEmailTemplateSpacer
        leftHandNavigation
        showOldEmailTemplatesOption
        socialMergeFieldsFrontend
        templateGalleryV2
        enableNewGalleryPage
    }
`);
