import { graphql } from '../../__graphqlTypes';

export const GET_GLOBAL_DATA = graphql(`
    query GetGlobalData {
        config {
            appFrontendUrl
            features {
                brandsDashboard {
                    enabled
                }
            }
            welcomePage {
                bookTrainingLink
                supportLink
            }
        }
        me {
            id
            firstName
            lastName
            profilePicture {
                signedUrl
            }
            roles
            username
            lavPreferenceOpen
            features {
                leftHandNavigation
            }
        }
    }
`);
